@import "../assets/fonts/source-serif/stylesheet";
@import "app/assets/css/cookieconsent";

body {
  font-size: 18px;
  line-height: 28px;
}

html {
  --scroll-behavior: smooth!important;
  scroll-behavior: smooth!important;
}

.page-banner {
  padding: 20px 0;
  border-bottom-right-radius: 64px;
  background-color: $color-primary;
  color: #ffffff;

  @include responsive(min-md) {
    padding: 40px 0;
  }

  .title {
    @include responsive(min-md) {
      margin-bottom: 0;
    }
  }

  .anchor-links {
    margin-bottom: 0;
    padding-left: 10px;
    margin-left: 20px;
    list-style: none;
    border-left: 1px solid #ffffff;

    li a {
      color: #ffffff;
      text-decoration: none;
    }
  }
}

.general-section {
  padding-bottom: 40px;

  .section-item {
    margin-top: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

h1, h2, h3 {
  font-family: 'Roboto Slab', serif;
  margin-bottom: 20px;
}

h1 {
  font-size: 32px;
  line-height: 42px;
}

h2 {
  font-size: 26px;
  line-height: 36px;
}

h3 {
  font-size: 20px;
  line-height: 30px;
}

.primary-container {
  max-width: 840px;
  padding-right: 20px;
  padding-left: 20px;
  margin: 0 auto;
}