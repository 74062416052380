header {
  background-color: $color-primary;
  color: #ffffff;

  .navbar {
    padding: 10px 0;
  }

  .navbar-brand {
    padding: 0;
    margin: 0 auto;
    flex-shrink: 0;

    img {
    }
  }

  .navbar-toggler {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding: 0;
    border: 0;
    width: 15px;
    height: 15px;
    z-index: 2;

    &:focus {
      box-shadow: none;
    }

    &[aria-expanded="true"] {
      transform: rotate(45deg);

      .line {
        position: absolute;
        top: 50%;

        &:first-child {
          transform: rotate(90deg);
        }

        &:last-child {
          margin-top: 0;
        }
      }

      + .navbar-collapse {
        transform: translateX(0);
      }
    }

    .line {
      width: 15px;
      height: 1.5px;
      background-color: #ffffff;
      border-radius: 2px;
      transition: ease transform .3s;

      &:last-child {
        margin-top: 5px;
      }
    }
  }

  .navbar-collapse {

    &.show {
      .nav-item {
        animation: translateY30 1.2s ease-out 0s 1 normal both;
      }
    }
  }

  .navbar-nav {
    padding: 25px 0;
  }

  .nav-item {
    opacity: 0;
    text-align: center;
  }

  .nav-link {
    color: #ffffff;

    @include responsive(min-md) {
      padding-right: 0 !important;
    }

    &:hover {
      color: #000000;
    }
  }
}