@font-face {
    font-family: 'Source Serif Pro';
    src: url('/assets/fonts/source-serif/subset-SourceSerifPro-Bold.woff2') format('woff2'),
        url('/assets/fonts/source-serif/subset-SourceSerifPro-Bold.woff') format('woff'),
        url('/assets/fonts/source-serif/subset-SourceSerifPro-Bold.ttf') format('truetype'),
        url('/assets/fonts/source-serif/subset-SourceSerifPro-Bold.svg#SourceSerifPro-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: block;
}

@font-face {
    font-family: 'Source Serif Pro';
    src: url('/assets/fonts/source-serif/subset-SourceSerifPro-Regular.woff2') format('woff2'),
        url('/assets/fonts/source-serif/subset-SourceSerifPro-Regular.woff') format('woff'),
        url('/assets/fonts/source-serif/subset-SourceSerifPro-Regular.ttf') format('truetype'),
        url('/assets/fonts/source-serif/subset-SourceSerifPro-Regular.svg#SourceSerifPro-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

