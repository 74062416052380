footer {
  background-color: $color-primary;
  color: #ffffff;
  padding: 10px 0;
  font-size: 14px;
  line-height: 28px;

  @include responsive(min-md) {
    padding: 20px 0;
  }

  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;

    li + li {
      display: flex;
      align-items: center;

      &:before {
        content: '•';
        padding: 0 7px;
      }
    }
  }

  a {
    text-decoration: none;
    color: #ffffff;
    transition: ease all .3s;

    &:hover {
      color: #000000;
    }
  }
}